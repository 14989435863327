* {
  margin: 0;
  padding: 0;
}

body {
  background-color: white;
  font-family: "Russo One", sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}

.text-align-right {
  text-align: right;
}

.price-color {
  color: #e8ae5c;
}

.barberBackground {
  width: 100%;
  /* filter: blur(2px); */
}

.header {
  width: 100%;
  position: relative;
  background-color: white;
}

.heroImage {
  width: 100%;
}

.booking-button {
  box-shadow: inset 0px 1px 0px 0px #cf866c;
  background: linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
  background-color: #d0451b;
  border-radius: 5px;
  border: 1px solid #942911;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 13px;
  padding: 9px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #854629;
  margin-top: 2%;
  margin-bottom: 25px;
}

.booking-button:hover {
  background: linear-gradient(to bottom, #bc3315 5%, #d0451b 100%);
  background-color: #bc3315;
}

.s1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.s1-h1 {
  margin-top: 5%;
  margin-bottom: 2%;
}

.social-icons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100px;
}

.social-icons {
  width: 24px;
  height: 24px;
}

.google-maps {
  width: 300px;
  height: 200px;
}

.s2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.s2-h2 {
  margin-top: 5%;
  margin-bottom: 2%;
}

.section-price-wrapper {
  display: flex;
}

.pricelist-items {
  padding-right: 15px;
}

.footer {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-image {
  width: 35%;
}

.section-contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.contact-form {
  display: block;
  width: 80%;
  margin: 0 auto;
  border: 1px solid #c1c1c1;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  background-color: #eaeaea;
}

.contact-form label {
  line-height: 2.7em;
  color: #4b5156;
}

.contact-form textarea {
  min-height: 100px;
  font-size: 14px;
}

.input-field {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
}

.submit-btn {
  margin-top: 10px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  background-color: black;
  border: none;
}

.form-contact-details {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  color: #4b5156;
  padding-top: 10px;
  padding-bottom: 10px;
}

.image-carousel-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-carousel {
  max-width: 80%;
  height: auto;
  margin-top: 5%;
}

@media (min-width: 1023px) {
  .section-row-column {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .s1 {
    margin-right: 5%;
  }

  .google-maps {
    width: 100%;
    height: 200px;
  }

  .contact-form {
    width: 35%;
  }

  .image-carousel {
    max-width: 50%;
  }
}
